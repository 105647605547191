import React from "react";
import styled from "styled-components";
import { Header, Label } from "semantic-ui-react";

const OldFileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 500px;
  overflow: auto;
  grid-gap: 5px;
`;

const OldFileSubHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? props.theme.navbar.colour : "none"};
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
`;

const OldFileImage = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
`;

const OldFileDateHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`;

const OldFileHolder = ({ enabled, previousUploads, selected, setSelected }) => {
  return (
    <div className={`field ${enabled ? "" : "disabled"}`}>
      <OldFileContainer>
        {previousUploads.map((upload) => (
          <OldFileSubHolder
            onClick={() => {
              if (selected === upload._id) {
                setSelected();
                return;
              }
              setSelected(upload._id);
            }}
            selected={selected === upload._id}
            key={upload._id}
          >
            <Header size="small" inverted>
              {upload.name}
            </Header>
            <OldFileDateHolder>
              <Label color="red" horizontal>
                Uploaded At
              </Label>{" "}
              {new Date(upload.uploadedAt).toLocaleDateString()}
            </OldFileDateHolder>
            <OldFileImage src={upload.previewUrl} alt={upload.name} />
          </OldFileSubHolder>
        ))}
      </OldFileContainer>
    </div>
  );
};

export default OldFileHolder;
