import React, { useState } from "react";
import Colors from "../../constants/Colors";
import styled from "styled-components";

const CompanyInfoContainer = styled.div`
  grid-area: head;
  background-color: ${Colors.background};
  position: relative;
  display: flex;
  justify-content: center;
`;

const CompanyInfoLogoHolder = styled.div`
  height: 60px;
  width: ${(props) => props.width ?? "60"}px;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const CompanyInfo = ({ logo }) => {
  const height = 60;
  const [width, setWidth] = useState();

  const calculateWidth = (naturalWidth, naturalHeight) => {
    const heightComparison = naturalWidth / naturalHeight;
    const newWidth = height * heightComparison;
    setWidth(newWidth);
  };

  return (
    <CompanyInfoContainer>
      <CompanyInfoLogoHolder width={width}>
        <img
          src={logo ?? `${process.env.PUBLIC_URL}/logos/pcs.png`}
          alt="Company Logo"
          style={{ height: `${height}px`, width: `${width ?? "60"}px` }}
          onLoad={(e) =>
            calculateWidth(e.target.naturalWidth, e.target.naturalHeight)
          }
        />
      </CompanyInfoLogoHolder>
    </CompanyInfoContainer>
  );
};

export default CompanyInfo;
