import React from 'react'
import ReactDOM from 'react-dom'
import App from './scenes/App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'
import theme from './definitions/theme'
import GlobalStyle from './util/globalStyle'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-boost'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { persistCache } from 'apollo-cache-persist'

const setupAndRender = async () => {
  const apolloServerUrl = await getApolloServerUrl()

  const getAppHeaders = () => ({
    'access-token': window.localStorage.getItem('token') || '',
    'app-code': 'artwork_upload'
  })

  const uploadLink = createUploadLink({
    uri: apolloServerUrl,
    headers: getAppHeaders
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getAppHeaders()
      }
    }
  })

  const cache = new InMemoryCache()

  const client = new ApolloClient({
    cache,
    link: authLink.concat(uploadLink),
    resolvers: {}
  })

  persistCache({
    cache,
    storage: window.sessionStorage
  })

  ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

setupAndRender()

async function getApolloServerUrl() {
  if (process.env.NODE_ENV !== 'production') {
    return `${window.location.protocol}//${window.location.hostname}:${4000}`
  }

  try {
    const clientServerGetConfigUrl = `${window.location.protocol}//${window.location.hostname}/app_config`
    const response = await fetch(clientServerGetConfigUrl)
    const config = await response.json()

    return config.apolloServer
  } catch (err) {
    console.error(err)
    throw err
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
