import React from "react";
import { Form } from "semantic-ui-react";

const MonoHolder = ({ checked, onChangeCheckedBox }) => {
  return (
    <Form.Checkbox
      label="Mono"
      checked={checked}
      onClick={() => onChangeCheckedBox((state) => !state)}
    />
  );
};

export default MonoHolder;
