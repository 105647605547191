import React from "react";
import { Form } from 'semantic-ui-react';

const URNHolder = ({ urnId, onChangeUrn }) => {
  return (
    <Form.Input
      value={urnId}
      onChange={(e) => onChangeUrn(e.target.value)}
      label="URN ID"
      placeholder="URN ID..."
    />
  );
};

export default URNHolder;
