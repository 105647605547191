import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { GET_ORGANISATIONS } from "../../graphql/mainControls";

const PersonalDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

const UserDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

const FormInput = styled(Form.Input)`
  margin-top: 3px !important;
  margin-bottom: 3px !important;
`;

const FormSelect = styled(Form.Select)`
  margin-top: 3px !important;
  margin-bottom: 3px !important;
`;

const Register = ({
  onChangeTitle,
  title,
  onChangeFirstName,
  firstName,
  onChangeSurname,
  surname,
  onChangeUsername,
  username,
  onChangeEmail,
  email,
  onChangePassword,
  password,
  onChangeConfirmPassword,
  confirmPassword,
  onChangeThirdPartyId,
  thirdPartyId,
  organisation,
  onChangeOrganisation,
}) => {
  const titleConfig = [
    { key: "mr", value: "mr", text: "Mr" },
    { key: "mrs", value: "mrs", text: "Mrs" },
    { key: "miss", value: "miss", text: "Miss" },
    { key: "ms", value: "ms", text: "Ms" },
  ];

  const { data, loading } = useQuery(GET_ORGANISATIONS);

  const formatOrganisationDropdownOptions = () => {
    if (!data) return [];
    return data?.getOrganisations.map((organisation) => ({
      key: organisation._id,
      text: organisation.name,
      value: organisation._id,
    }));
  };

  const options = formatOrganisationDropdownOptions();

  return (
    <>
      <PersonalDetailsContainer>
        <FormSelect
          options={titleConfig}
          label="Title"
          value={title}
          onChange={(_, { value }) => onChangeTitle(value)}
        />
        <FormInput
          key="firstName"
          placeholder="First Name"
          fluid
          label="First Name"
          labelPosition="left"
          icon="user circle"
          iconPosition="left"
          value={firstName}
          onChange={(e) => onChangeFirstName(e.target.value)}
          autoFocus
        />
        <FormInput
          key="surname"
          placeholder="Surname"
          label="Surname"
          labelPosition="left"
          fluid
          icon="user circle"
          iconPosition="left"
          value={surname}
          onChange={(e) => onChangeSurname(e.target.value)}
        />
      </PersonalDetailsContainer>
      <UserDetailsContainer>
        <FormInput
          key="username"
          icon="user"
          iconPosition="left"
          placeholder="Username"
          fluid
          autoComplete="username"
          value={username}
          onChange={(e) => onChangeUsername(e.target.value)}
          label="Username"
          labelPosition="left"
        />
        <FormInput
          key="email"
          icon="mail"
          iconPosition="left"
          placeholder="Email"
          fluid
          autoComplete="email"
          value={email}
          onChange={(e) => onChangeEmail(e.target.value)}
          label="Email"
          labelPosition="left"
        />
        <FormInput
          key="password"
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          fluid
          autoComplete="current-password"
          value={password}
          onChange={(e) => onChangePassword(e.target.value)}
          label="Password"
          labelPosition="left"
        />

        <FormInput
          key="confirmPassword"
          icon="lock"
          iconPosition="left"
          placeholder="Confirm password"
          type="password"
          fluid
          value={confirmPassword}
          onChange={(e) => onChangeConfirmPassword(e.target.value)}
          label="Confirm Password"
          labelPosition="left"
        />
        <FormInput
          key="thirdPartyId"
          icon="id badge"
          iconPosition="left"
          placeholder="Third Party ID"
          type="text"
          fluid
          value={thirdPartyId}
          onChange={(e) => onChangeThirdPartyId(e.target.value)}
          label="Third Party ID"
          labelPosition="left"
        />
        <Form.Dropdown
          label="Organisation"
          options={options}
          loading={loading}
          fluid
          selection
          pointing="bottom"
          value={organisation}
          onChange={(_, { value }) => onChangeOrganisation(value)}
        />
      </UserDetailsContainer>
    </>
  );
};

export default Register;
