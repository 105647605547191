import gql from 'graphql-tag'

export const CHECK_URN = gql`
  query GetUrn($urn: String!) {
    getJobByUrn(urn: $urn) {
      success
      job {
        urn
      }
    }
  }
`

export const UPLOAD_ARTWORK = gql`
  mutation UploadArtwork($file: Upload!, $input: UploadArtworkInput!) {
    uploadArtwork(file: $file, input: $input) {
      name
    }
  }
`

export const GET_CURRENT_USER_ARTWORK = gql`
  query GetCurrentUserArtwork {
    getUploadedArtworkForCurrentUser {
      _id
      name
      previewUrl
      uploadedAt
    }
  }
`

export const UPLOAD_PREVIOUS_ARTWORK = gql`
  mutation UploadPreviousArtwork($input: PreviousArtworkUploadMutationInput!) {
    uploadPreviousArtwork(input: $input) {
      name
    }
  }
`

export const GET_CURRENT_USER = gql`
  {
    currentUser {
      _id
      organisation {
        _id
        reference
        logo
      }
    }
  }
`

export const GET_ORGANISATIONS = gql`
  query GetOrganisations {
    getOrganisations {
      _id
      name
    }
  }
`

export const DIRECT_S3_UPLOAD_ARTWORK_UPDATE = gql`
  mutation DirectS3UploadArtworkUpdate($input: UpdateArtworkInput!) {
    directS3UploadArtworkUpdate(input: $input) {
      name
    }
  }
`

export const GET_UPLOAD_URL_AND_FILE_IDS = gql`
  query GetUploadUrlAndFileIds {
    getUploadUrlAndFileIds {
      id
      url
      previewId
    }
  }
`

export const GET_PUBLICATIONS_WITH_FUTURE_DATE = gql`
  query getPublicationsWithFutureAppearances {
    getPublicationsWithFutureAppearances {
      _id
      name
      code
    }
  }
`

export const GET_PUBLICATIONS_FOR_ORGANISATION = gql`
  query GetPublicationsForOrganisation($organisation: ID!) {
    getPublicationsForOrganisation(organisation: $organisation) {
      _id
      name
      code
    }
  }
`

export const GET_PUBLICATION_DATES = gql`
  query getFutureDatesForPublication($pubName: String!) {
    getFutureDatesForPublication(pubName: $pubName) {
      _id
      appearanceDate
    }
  }
`
