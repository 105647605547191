import React from 'react';
import { Button } from 'semantic-ui-react';
import logout from '../../util/logout';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const LogoutContainer = styled.div`
position: absolute;
right: 5px;
top: 18px;
`

const Logout = () => {
  const history = useHistory();
  const onLogoutHandler = () => {
    logout();
    history.push("/");
  }
  return (
    <LogoutContainer>
      <Button negative onClick={onLogoutHandler} icon="sign out"></Button>
    </LogoutContainer>
  )
}

export default Logout;