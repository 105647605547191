import React from "react";
import { Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";

const StyledLoader = styled(Loader)`
  color: white;
  font-size: 1.5em;
`;

const Loading = ({ content, loading }) => {
  return (
    <Modal open={loading}>
      <StyledLoader content={content} active={loading} />
    </Modal>
  );
};

export default Loading;
