import React from "react";
import { Form } from "semantic-ui-react";
import URNHolder from "../../URNHolder/URNHolder";
import PublicationHolder from "../../PublicationHolder/PublicationHolder";
import InstructionsHolder from "../../InstructionsHolder/InstructionsHolder";
import MonoHolder from "../../MonoHolder/MonoHolder";
import styled from "styled-components";

const MainControlLeftContainer = styled(Form.Group)`
  flex-direction: column !important;
  margin: 0 !important;
`;

const SubmitContainer = styled(Form.Field)`
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
  height: 100%;
`;

const MainControlLeft = ({
  URN_HOLDER,
  urnId,
  setUrnId,
  cms,
  setCms,
  cols,
  setCols,
  date,
  setDate,
  publication,
  setPublication,
  instructions,
  setInstructions,
  mono,
  setMono,
  setModal,
}) => {
  return (
    <MainControlLeftContainer>
      <Form.Field>
        {URN_HOLDER ? (
          <URNHolder urnId={urnId} onChangeUrn={setUrnId} />
        ) : (
          <PublicationHolder
            cms={cms}
            onChangeCms={setCms}
            cols={cols}
            onChangeCols={setCols}
            insertDate={date}
            onChangeInsertDate={setDate}
            publication={publication}
            onChangePublication={setPublication}
          />
        )}
      </Form.Field>
      <Form.Field>
        <InstructionsHolder
          instructions={instructions}
          onChangeInstructions={setInstructions}
        />
        <MonoHolder checked={mono} onChangeCheckedBox={setMono} />
      </Form.Field>
      <SubmitContainer>
        <Form.Button onClick={() => setModal(true)} primary>
          Submit
        </Form.Button>
      </SubmitContainer>
    </MainControlLeftContainer>
  );
};

export default MainControlLeft;
