import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

label {
  color: white !important;
}

.checked label {
  color: white !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(
      306deg,
      rgba(54, 54, 54, 0.05) 0%,
      rgba(54, 54, 54, 0.05) 33.333%,
      rgba(85, 85, 85, 0.05) 33.333%,
      rgba(85, 85, 85, 0.05) 66.666%,
      rgba(255, 255, 255, 0.05) 66.666%,
      rgba(255, 255, 255, 0.05) 99.999%
    ),
    linear-gradient(
      353deg,
      rgba(81, 81, 81, 0.05) 0%,
      rgba(81, 81, 81, 0.05) 33.333%,
      rgba(238, 238, 238, 0.05) 33.333%,
      rgba(238, 238, 238, 0.05) 66.666%,
      rgba(32, 32, 32, 0.05) 66.666%,
      rgba(32, 32, 32, 0.05) 99.999%
    ),
    linear-gradient(
      140deg,
      rgba(192, 192, 192, 0.05) 0%,
      rgba(192, 192, 192, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 33.333%,
      rgba(109, 109, 109, 0.05) 66.666%,
      rgba(30, 30, 30, 0.05) 66.666%,
      rgba(30, 30, 30, 0.05) 99.999%
    ),
    linear-gradient(
      189deg,
      rgba(77, 77, 77, 0.05) 0%,
      rgba(77, 77, 77, 0.05) 33.333%,
      rgba(55, 55, 55, 0.05) 33.333%,
      rgba(55, 55, 55, 0.05) 66.666%,
      rgba(145, 145, 145, 0.05) 66.666%,
      rgba(145, 145, 145, 0.05) 99.999%
    ),
    linear-gradient(90deg, rgb(9, 201, 186), rgb(18, 131, 221));
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 0.5fr 10fr 10fr;
  grid-template-areas: "head head head head head" "main main main main main" "main main main main main";
}
`

export default GlobalStyle