import React, { useState, useEffect } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { useQuery } from '@apollo/react-hooks'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  GET_PUBLICATIONS_FOR_ORGANISATION,
  GET_PUBLICATION_DATES
} from '../../graphql/mainControls'
import useCurrentUser from '../../hooks/useCurrentUser'

const PublicationHolder = ({
  publication,
  onChangePublication,
  insertDate,
  onChangeInsertDate,
  cms,
  onChangeCms,
  cols,
  onChangeCols
}) => {
  const [publicationOptions, setPublicationOptions] = useState([])
  const [pubDates, setPubDates] = useState([])
  const { user } = useCurrentUser()

  const { data: response, loading } = useQuery(
    GET_PUBLICATIONS_FOR_ORGANISATION,
    {
      variables: {
        organisation: user?.organisation[0]._id
      },
      skip: !user?.organisation[0]._id,
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    const formatPublicationOptions = () => {
      return response.getPublicationsForOrganisation.map((publication) => {
        return {
          key: publication._id,
          text: publication.name,
          value: publication.name
        }
      })
    }

    if (response && !loading) {
      const pubOptions = formatPublicationOptions()
      setPublicationOptions(pubOptions)
    }
  }, [response, loading])

  const usePublicationDates = (pubName) => {
    const { data } = useQuery(GET_PUBLICATION_DATES, {
      variables: { pubName },
      skip: !pubName,
      fetchPolicy: 'no-cache'
    })

    useEffect(() => {
      if (data) {
        const formattedDates = formatDates(data)
        setPubDates(formattedDates)
      }
    }, [data])
  }

  const formatDates = (data) => {
    return data.getFutureDatesForPublication.map(
      (appearance) => new Date(appearance.appearanceDate)
    )
  }

  usePublicationDates(publication)

  const handlePublicationChange = (value) => {
    if (!value) {
      setPubDates([])
      onChangeInsertDate()
    }
    onChangePublication(value)
  }

  return (
    <>
      <Form.Group required={true}>
        <Form.Field required={true}>
          <Form.Select
            required={true}
            onChange={(_, { value }) => {
              handlePublicationChange(value)
            }}
            label="Publication"
            options={publicationOptions}
            value={publication}
            clearable
          />
          <label>Publication Date</label>
          <DatePicker
            required={true}
            selected={insertDate}
            includeDates={pubDates}
            disabled={pubDates.length === 0}
            onChange={(date) => onChangeInsertDate(date)}
            dateFormat="dd/MM/yyyy"
            isClearable={true}
          />
          <Header size="small" inverted>
            Advert Size
          </Header>
          <Form.Input
            required={true}
            type="number"
            label="Depth"
            value={cms ?? ''}
            onChange={(e) => onChangeCms(e.target.value)}
          />
          <Form.Input
            required={true}
            type="number"
            label="Cols"
            value={cols ?? ''}
            onChange={(e) => onChangeCols(e.target.value)}
          />
        </Form.Field>
      </Form.Group>
    </>
  )
}

export default PublicationHolder
