import React from "react";
import { Form } from "semantic-ui-react";

const Login = ({ onChangeUsername, onChangePassword, username, password }) => {
  return (
    <>
      <Form.Input
        autoFocus
        key="username"
        icon="user"
        iconPosition="left"
        placeholder="Username"
        size="large"
        fluid
        autoComplete="username"
        value={username}
        onChange={(e) => onChangeUsername(e.target.value)}
      />
      <Form.Input
        key="password"
        icon="lock"
        iconPosition="left"
        placeholder="Password"
        type="password"
        size="large"
        fluid
        autoComplete="current-password"
        value={password}
        onChange={(e) => onChangePassword(e.target.value)}
      />
    </>
  );
};

export default Login;
