import gql from "graphql-tag";

export const LOGIN = gql`
  mutation Login(
    $username: String!
    $password: String!
    $artworkUser: Boolean
  ) {
    login(username: $username, password: $password, artworkUser: $artworkUser) {
      success
      message
      user {
        token
      }
    }
  }
`;

export const REGISTER = gql`
  mutation Register($user: UserRegistration!) {
    register(user: $user) {
      success
      message
      user {
        token
      }
    }
  }
`;
