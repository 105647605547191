import React, { useMemo } from 'react'
import CompanyInfo from '../../components/CompanyInfo/CompanyInfo'
import MainControls from '../../components/MainControls/MainControls'
import Logout from '../../components/Logout/Logout'
import useCompanyLogo from '../../hooks/useCompanyLogo'
import Loading from '../../components/Loading/Loading'
import useCurrentUser from '../../hooks/useCurrentUser'

const MainApp = () => {
  const logo = useCompanyLogo()
  const { user, loading } = useCurrentUser()

  const useUrn = useMemo(() => {
    if (!user) return false
    const noneUrnOrganisations = JSON.parse(
      process.env.REACT_APP_NONE_URN_ORGS || '["RCP", "RP"]'
    )
    const userOrganisation =
      (user?.organisation?.length || 0) === 1 ? user.organisation[0] : null
    return userOrganisation &&
      noneUrnOrganisations?.includes(userOrganisation.reference)
      ? false
      : true
  }, [user])

  return loading ? (
    <Loading loading={true} content="Loading your options..." />
  ) : (
    <>
      <CompanyInfo logo={logo ?? `${process.env.PUBLIC_URL}/logos/pcs.png`} />
      <Logout />
      <MainControls urnHolder={useUrn} />
    </>
  )
}

export default MainApp
