import React from 'react'
import './App.css'
import MainApp from './MainApp/MainApp'
import Auth from './Auth/Auth'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import AuthRoute from '../routes/AuthRoute'
import logout from '../util/logout'

const App = () => {
  window.addEventListener('unload', logout)

  return (
    <Router>
      <Switch>
        <AuthRoute component={MainApp} path="/dashboard" exact />
        <Route component={Auth} exact path="/" />
        <Redirect from="*" to="/" exact />
      </Switch>
    </Router>
  )
}

export default App
