import React from "react";
import { TextArea } from "semantic-ui-react";
import styled from "styled-components";

const StyledTextArea = styled(TextArea)`
  resize: none !important;
  height: 200px;
`;

const InstructionsHolder = ({ instructions, onChangeInstructions }) => {
  return (
    <StyledTextArea
      label="Instructions"
      placeholder="Instructions..."
      value={instructions}
      onChange={(_, e) => onChangeInstructions(e.value)}
      rows={3}
    />
  );
};

export default InstructionsHolder;
