import React from "react";
import { Modal, Button } from "semantic-ui-react";
import PropTypes from "prop-types";

const CustomModal = ({
  setModal,
  modalState,
  header,
  message,
  color,
  clearMessage,
  buttons,
}) => {
  return (
    <Modal
      open={modalState}
      onOpen={() => setModal(true)}
      onClose={() => {
        setModal(false);
        clearMessage();
      }}
    >
      {header}
      <Modal.Content>
        <Modal.Description>{message}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {buttons ? (
          buttons
        ) : (
          <Button
            color={color}
            onClick={() => {
              setModal(false);
              clearMessage();
            }}
            title="Okay"
          >
            Okay
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};

CustomModal.propTypes = {
  setModal: PropTypes.func,
  modalState: PropTypes.bool,
  header: PropTypes.element,
  message: PropTypes.string,
  color: PropTypes.string,
  clearMessage: PropTypes.func,
};

export default CustomModal;
