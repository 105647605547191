import useCurrentUser from "./useCurrentUser"

const useCompanyLogo = () => {
  const { user } = useCurrentUser()
  const hasMoreThanOneOrganisations = user?.organisation?.length > 1
  const firstOrganisation = hasMoreThanOneOrganisations ? null : user?.organisation[0]
  return firstOrganisation?.logo
}

export default useCompanyLogo
