import React from "react";
import styled from "styled-components";
import { Form, Checkbox, Label } from "semantic-ui-react";
import OldFileHolder from "../../OldFileHolder/OldFileHolder";
import NewFileHolder from "../../NewFileHolder/NewFileHolder";

const MainControlRightContainer = styled(Form.Group)`
  flex-direction: column !important;
  margin: 0 !important;
`;

const MainControlOld = styled(Form.Field)`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  color: white !important;
  input:checked ~ label {
    color: white !important;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledLabel = styled(Label)`
  margin-left: 5px !important;
`;

const MainControlRight = ({
  PREVIOUS_FILE_STATE,
  NEW_FILE_STATE,
  fileState,
  setFileState,
  previousUploads,
  oldFileSelected,
  setOldFileSelected,
  setFileUpload,
  setFileUrl,
  fileUrl,
  setImageType,
  imageType,
}) => {
  return (
    <MainControlRightContainer>
      <TopContainer>
        <StyledCheckbox
          toggle
          onChange={() =>
            setFileState((prevState) => {
              if (prevState === NEW_FILE_STATE) return PREVIOUS_FILE_STATE;
              return NEW_FILE_STATE;
            })
          }
        />
        <StyledLabel color="red">
          {fileState === NEW_FILE_STATE
            ? "New File Upload"
            : "Previous File Upload"}
        </StyledLabel>
      </TopContainer>

      {fileState === PREVIOUS_FILE_STATE ? (
        <MainControlOld>
          <OldFileHolder
            enabled={fileState === PREVIOUS_FILE_STATE}
            previousUploads={previousUploads}
            selected={oldFileSelected}
            setSelected={setOldFileSelected}
          />
        </MainControlOld>
      ) : null}
      {fileState === NEW_FILE_STATE ? (
        <NewFileHolder
          onUpload={setFileUpload}
          enabled={fileState === NEW_FILE_STATE}
          setFileUrl={setFileUrl}
          fileUrl={fileUrl}
          imageType={imageType}
          setImageType={setImageType}
        />
      ) : null}
    </MainControlRightContainer>
  );
};

export default MainControlRight;
