import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({component: Component, render, ...props}) => {
  const token = localStorage.getItem("token");
  return (
    <Route
      render={(otherProps) => {
        if (!token) {
          return <Redirect to="/" />;
        }

        if (render) {
          return render()
        }

        if (!Component) {
          return null
        }

        return <Component {...otherProps} />
      }}
      {...props}
    />
  );
};

export default AuthRoute;
