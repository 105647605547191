import { useCallback } from 'react'
import { useApolloClient } from '@apollo/react-hooks'

export function useLazyQuery(query, options) {
  const client = useApolloClient()

  return useCallback(
    (variables) =>
      client.query({
        query,
        variables,
        ...(options || {})
      }),
    [client, query, options]
  )
}
