import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "../graphql/mainControls";

const useCurrentUser = () => {
  const { data, loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "network-only",
  });
  return { user: data?.currentUser, loading };
};

export default useCurrentUser;
